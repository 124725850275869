body {
  #header {
    .site-logo-responsive-v1 {
      .left_logo-link {
        float: left;
      }
      .right_logo-link {
        float: none;
        img {
          vertical-align: top;
        }
      }
    }
  }
}

#footer {
  .footer-top-messenger {
    overflow: hidden;
  }
}

#foreground-node {
  &.ob_overlay {
    .close-container {
      a.close-link {
        background: url('/media/export/cms/icon-close.png') no-repeat;
      }
    }
  }
}

#blue_ocean {
  #blueocean_intro {
    .blueocean_heading {
      padding: 78px 113px 0px 113px;
    }
    .blueocean_desc {
      padding: 40px 120px;
      float: left;
    }
  }
}

.product-content-hotlist-v1 {
  .row {
    .inner {
      img.product-image {
        max-width: inherit;
      }
    }
  }
}

.navigation-right {
  .mm-formatter-v1 {
    .mm-menu {
      li span.title {
        padding: 0px 8px;
      }
    }
  }
}

#foundation_finder {
  #ff_spp {
    .ff_spp_product {
      #ff_header_container {
        .ff_social {
          ul.ff_social_share {
            li.ff_instagram {
              a {
                background: url('/media/export/cms/icon-instagram.png') no-repeat scroll 10px 5px transparent;
                background-size: 16px 16px;
              }
            }
          }
        }
      }
    }
    #works-with-results {
      .result {
        .image {
          a.quickshop-link {
            img.qs-active {
              display: none;
            }
          }
        }
      }
    }
  }
}

.section-power-couples-collection {
  #foreground-node {
    #quickshop {
      border-top: 0;
      padding-top: 8px;
    }
  }
}

.section-foundation-looks {
  .full-width-2-row-v1 {
    .outer-wrap {
      .top {
        .subheading {
          float: right;
        }
      }
    }
  }
}

.cl-mpp-product-layout-v1 {
  .mpp-grid {
    #mpp-product-grid {
      #quickshop-wrapper {
        .col1 {
          .skin-type {
            .quickshop-type {
              li a {
                height: 115px !important;
              }
            }
          }
        }
      }
    }
    li.mpp-box {
      .product-info {
        .price2 {
          color: $color-red;
        }
        .strike_out {
          text-decoration: line-through;
        }
      }
    }
    li.custom-tout {
      img.custom-tout-img {
        max-width: 100% !important;
        width: 100% !important;
      }
    }
    ul.view-large {
      li.mpp-box {
        width: auto;
      }
    }
  }
  .mpp-filter {
    height: 80px;
  }
}

.recommended-for-you {
  .prod-shelf-row {
    .products {
      .product {
        @include swap_direction(padding, 0 10px 50px 0);
        width: 40%;
        .my-account-sku {
          .btn-add-to-bag {
            margin-#{$ldirection}: 25px;
          }
          .product-image {
            width: 170px;
          }
          .product-info {
            margin-#{$ldirection}: 25px;
            .product-name {
              min-height: 40px;
              a {
                color: $color-text-black;
              }
            }
          }
        }
      }
    }
  }
}

.flex-direction-nav {
  a {
    opacity: 1 !important;
  }
}

.features-and-benefits_row_v1 {
  .row {
    .benefit-col {
      top: -153px;
    }
  }
}

.features-and-benefits-formatter-v1 {
  height: 541px;
}

.pc_spp_view {
  .module-spp-detail {
    .container {
      .col1 {
        .badge.badge_109 {
          background: $color-hot-pink none repeat scroll 0 0;
          line-height: 16px;
          p {
            font-size: 11px;
            line-height: 16px;
          }
        }
      }
    }
  }
}

#content .cl-mpp-product-layout-v1 {
  .mpp-grid {
    li.mpp-box {
      .badge.badge_109 {
        background: $color-hot-pink none repeat scroll 0 0;
        line-height: 14px;
        p {
          font-size: 11px;
          line-height: 16px;
        }
      }
    }
  }
}

#quickshop {
  .temp_out_of_stock_msg,
  .coming_soon_stock_msg {
    a.btn-comingsoon {
      background-color: $color-btn-inverse;
      color: $color-cl-green-2;
      border: 1px solid $color-cl-green-2;
    }
  }
}

body.page-products-holiday .cl-mpp-product-layout-v1 {
  .mpp-wrapper {
    background-position: center top;
    background-repeat: no-repeat;
    max-width: 100%;
    padding: 0;
  }
  .mpp-filter {
    padding-top: 0px;
    min-height: 412px;
    border-bottom: 1px solid $color-light-grey;
    h1 {
      display: none;
    }
    .btn-filter-expand-collapse.small.expanded {
      display: none;
    }
  }
  .mpp-view-options {
    display: none;
    max-width: 1000px;
    margin: 0 auto;
    min-height: 35px;
    .mpp-sort-by {
      display: none;
    }
  }
  .mpp-grid {
    margin-top: 40px;
  }
}

#custom_mpp_hdr {
  border: 1px solid $color-black;
  min-height: 340px;
  width: 960px;
  margin: 0 auto;
  .custom_mpp_inner {
    margin-top: 150px;
    width: 50%;
    text-align: center;
    p {
      margin-top: 0;
      color: $color-black;
      letter-spacing: 0;
      font-size: 17px;
    }
    h4 {
      margin-top: 0;
      color: $color-black;
      font-size: 19px;
      font-family: HelveticaNeueLTStd75Bold;
      text-transform: uppercase;
      letter-spacing: 0.4em;
    }
  }
}

#holiday-subnav {
  background-color: $color-white;
  height: 72px;
  ul {
    width: 1000px;
    margin: 0 auto;
    padding-top: 25px;
    text-align: center;
  }
  li {
    display: inline-block;
    list-style-type: none;
    padding: 0 14px;
    vertical-align: top;
    line-height: 12px;
    a {
      color: $color-black;
      text-transform: uppercase;
      text-decoration: none !important;
      font-size: 13px;
      letter-spacing: 0.2em;
      font-family: HelveticaNeueLTStd65Medium;
    }
    a.subnav_select {
      color: $color-hot-pink;
    }
  }
}

.tout-image {
  &.tout {
    background-size: auto auto !important;
    .tout-info {
      padding-top: 240px !important;
      text-align: center !important;
      width: auto !important;
      .heading {
        color: $color-black !important;
        font-family: 'HelveticaNeueLTStd65Medium', Arial, sans-serif !important;
        font-size: 19px !important;
        line-height: 19px !important;
      }
      .body {
        font-size: 19px !important;
        width: 100% !important;
        margin-top: 2px !important;
      }
      .link {
        margin-top: 10px !important;
        a.button {
          color: $color-white !important;
          background-color: $color-black !important;
        }
      }
    }
  }
}

.tout-ff-v1 {
  &.tout {
    .tout-info {
      padding-top: 15px !important;
    }
    .image {
      float: none;
      margin: 0px;
      text-align: center;
      width: auto;
      margin-top: 10px;
    }
  }
}

.mpp-product-v1 {
  .product:hover {
    a.button.btn-quickview {
      display: none;
    }
  }
}

#password_request,
#password_sent {
  #footer {
    bottom: auto;
  }
}
