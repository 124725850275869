body.pc_spp_view {
  #main {
    .price {
      margin-bottom: 20px;
      border-top: none;
      padding-top: 0px;
      #product-afterpay-logo {
        background: url('/media/export/cms/afterpay/ap-logo.png') no-repeat;
        width: 100px;
        height: 25px;
        margin: 10px 0px 0px;
        vertical-align: middle;
        background-size: 100px;
      }
      #product-afterpay-installment-text {
        width: 390px;
        font-family: $base-thin-font-family;
        #afterpay-learnmore {
          color: $color-black;
          text-transform: uppercase;
          text-decoration: underline;
          font-size: 15px;
        }
      }
    }
  }
  .module-spp-detail {
    .container {
      .col2 {
        .support {
          #lpbuttonvideo {
            display: block;
            margin-top: 20px;
          }
        }
      }
    }
  }
  .sticky_content {
    .spp_product_status {
      clear: none;
    }
  }
  .ingredients-section {
    margin: 20px 0;
    .ingredients_header {
      cursor: pointer;
    }
    .ingredients_content {
      padding-top: 3px;
    }
    .ingredients_more {
      background-image: url(/media/export/cms/images/toggle_plus.png);
    }
    .ingredients_less {
      background-image: url(/media/export/cms/images/minus_icon.png);
      margin-bottom: 4px;
    }
    .ingredients_toggle {
      display: inline-block;
      height: 10px;
      width: 10px;
    }
  }
}

#header,
#navigation {
  .outer-wrap {
    max-width: 1140px;
  }
}

.offer-promo-carousel-v1 {
  width: 400px;
}

#footer .footer-bottom {
  .afterpay-content,
  .afterpay-content-mb {
    display: none !important;
  }
}

.module-spp-detail {
  .price.price2 {
    div {
      color: $color-black;
    }
  }
}

li#quickshop-wrapper {
  padding: 0;
  max-width: 1000px;
  margin: 0 auto;
  list-style: none;
}
