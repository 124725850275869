.store-locator-v1 {
  .store-locator-body {
    font-family: $base-font-family;
    input {
      border: solid 1px $color-grey;
    }
    .outer-wrap {
      .locations_map_panel {
        margin-top: 20px;
      }
      .store-search-controls {
        .city_container {
          padding-top: 2px;
        }
      }
      .signup {
        width: 465px;
        float: left;
        margin: 20px 0 0 15px;
        .signup-header {
          .signup-header-title {
            border-top: none;
            font-size: 21px;
            text-transform: none;
            margin: 0 0 5px 0;
          }
        }
        fieldset.signup_form_panel {
          border: none;
          margin: 0;
          padding: 20px 0;
          .top-row {
            float: left;
            .name_container {
              margin-left: 20px;
            }
          }
          .bottom-row {
            float: left;
            margin-top: 20px;
            .button {
              margin-left: 20px;
            }
          }
          .signup_container {
            input {
              border: none;
            }
          }
        }
        input {
          padding: 0px 10px;
          width: 215px;
          height: 37px;
          line-height: 37px;
          font-size: 12px;
          letter-spacing: 1px;
          font-family: $base-font-family-roman;
        }
      }
      .search {
        width: 479px;
        float: left;
        border-right: 1px solid $color-shade-grey;
        .search_row {
          margin-bottom: 8px;
          .form_element {
            label {
              display: block;
            }
          }
          input {
            padding: 0px 10px;
            width: 215px;
            height: 37px;
            margin: 5px 0px 10px 0px;
          }
          .button {
            padding: 8px 16px 7px 0px;
            width: 215px;
            margin-top: 5px;
            line-height: 20px;
            font-size: 11px;
          }
          .state_container {
            float: right;
            margin-right: 14px;
          }
          .door_container {
            .sbHolder {
              width: 185px;
            }
          }
          .distance_container {
            float: left;
            margin-right: 20px;
            label {
              float: left;
              width: 99px;
              text-align: center;
              line-height: 37px;
              text-transform: none;
            }
            .sbHolder {
              width: 90px;
            }
          }
          .search_alternative_text {
            font-size: 11px;
            width: 33px;
            padding: 7px 0;
            margin: 0;
            margin-top: 6px;
          }
          .sbHolder {
            margin: 5px 1px 10px 0px;
            width: 215px;
            height: 36px;
            .sbToggle {
              background-position: 8px 15px;
            }
            .sbSelector {
              height: 34px;
              padding: 5px;
            }
          }
          .search_container {
            a.button {
              width: 61px;
              border: none;
              height: 37px;
              padding: 10px;
            }
          }
        }
      }
    }
  }
}
