.device-pc {
  .counter_block {
    height: 85px;
    background-color: $color-black;
    .counter_label {
      margin: 25px auto 0px;
    }
    .counter {
      margin: 10px auto 0px;
    }
  }
  .counter_header {
    td {
      font-size: 38px;
    }
  }
}
