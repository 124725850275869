#sticky-bar {
  .mm-menu .item-more {
    width: 85px;
  }
}

.thewink-hp-redesign {
  .region-page-top {
    display: none;
  }
}

.customer-care-help-topics {
  #customer_service_chat {
    margin-top: 0;
    a {
      margin-top: 0;
    }
  }
}
